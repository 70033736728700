@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-none::-webkit-scrollbar {
  display: none;
}
* {
  font-family: "Inter", sans-serif;
}

/* Add this new utility class for the sleek scrollbar */
.scrollbar-sleek {
  scrollbar-width: thin;
  scrollbar-color: #888 #1e1e1e;
}

.scrollbar-sleek::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-sleek::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.scrollbar-sleek::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  border: 2px solid #1e1e1e;
}

.scrollbar-sleek::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
:root {
  --shake-duration: 0.82s;
  --shake-interval: 1s;
  --shake-offset: 3px;
  --shake-zoom: 1.09;
  --shake-zoom-mid: 1.06;
  --shake-zoom-start: 1.03;
}



@keyframes shake {
  0% {
    transform: scale(1);
  }
  9% {
    transform: scale(var(--shake-zoom-start));
  }
  18% {
    transform: scale(var(--shake-zoom-mid));
  }
  27% {
    transform: rotate(-1deg) scale(var(--shake-zoom));
  }
  36% {
    transform: rotate(0deg) scale(var(--shake-zoom));
  }
  45% {
    transform: rotate(1deg) scale(var(--shake-zoom));
  }
  54% {
    transform: rotate(0deg) scale(var(--shake-zoom));
  }
  63% {
    transform: rotate(-1deg) scale(var(--shake-zoom));
  }
  72% {
    transform: rotate(0deg) scale(var(--shake-zoom));
  }
  81% {
    transform: scale(var(--shake-zoom-mid));
  }
  90% {
    transform: scale(var(--shake-zoom-start));
  }
  100% {
    transform: scale(1);
  }
}

.animate-shake {
  animation: shake var(--shake-duration) cubic-bezier(0.36, 0.07, 0.19, 0.97)
    both;
}


@layer utilities {
  .text-stroke {
    -webkit-text-stroke: 1px white;
    color: transparent;
  }
  .text-fill {
    -webkit-text-fill-color: white;
  }
}